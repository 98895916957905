import * as React from 'react';
import Box from '@lce/slice_v2/Layout/Box';

// eslint-disable-next-line max-lines-per-function
const LegalHtmlProvider: React.FC = ({ children }) => (
  <Box sx={{
    'h1': {
      fontFamily: 'primary',
      color: 'primary',
      fontSize: 16,
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    'h2': {
      fontFamily: 'primary',
      color: 'primary',
      fontSize: 14,
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    'h3': {
      fontFamily: 'primary',
      color: 'primary',
      fontSize: 12,
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    'h4': {
      fontFamily: 'primary',
      color: 'primary',
      fontSize: 10,
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    'h5': {
      fontFamily: 'primary',
      color: 'primary',
      fontSize: 8,
      fontWeight: 'bold',
    },
    'h6': {
      fontFamily: 'primary',
      color: 'primary',
      fontSize: 6,
      fontWeight: 'bold',
    },
    'a': {
      color: 'primary',
      textDecoration: 'underline',
    },
    '.tablediv': {
      overflow: 'auto',
    },
  }}>
    {children}
  </Box>
);

export default LegalHtmlProvider;
